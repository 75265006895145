import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import React from 'react'
import { useRef } from 'react';

const HomeBanner = () => {

  
  
  return (

      
        <div  className='py-40'>
          <div className='relative homeBannerContainer' >
          <img
            src="images/general/home.jpg"
            alt="logo"
            className="min-h-[30rem] w-full object-cover object-right xl:w-full lg:h-full relative"
          />
          <div className='absolute h-full w-full   top-0 left-0 z-10 '>
            <div className='h-full w-full  pl-24  relative'>
               <img
                src="images/wheat/1.png"
                alt="logo"
                className="absolute left-[13.02%] -top-[18%] h-80 banner-wheat-1"
              />

              <img
                src="images/wheat/2.png"
                alt="logo"
                className="absolute  h-56 left-[11.82%] top-[45%] banner-wheat-2"
              />


            <img
                src="images/wheat/3.png"
                alt="logo"
                className="absolute    h-72 left-[44.53%] -bottom-[20%] banner-wheat-3"
              />

            <img
                src="images/wheat/4.png"
                alt="logo"
                className="absolute    h-40 left-[37.03%] top-[27%] banner-wheat-4"
              />

<           img
                src="images/wheat/5.png"
                alt="logo"
                className="absolute    h-48 left-[27.08%] -top-[20%] banner-wheat-5"
              />
            </div>
          </div>
          </div>
        </div>
     


  )
}

export default HomeBanner